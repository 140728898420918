import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Footer() {
  const [copied, setCopied] = useState(false);

  return (
    <footer className="mt-48">
      <div
        className="shadow-xl duration-200 bg-neutral-900"
        id="contact"
      >
        <div className="mx-auto text-white flex w-full max-w-[1140px] flex-col-reverse justify-between gap-16 px-4 py-16 sm:px-8 md:flex-row lg:px-16">
          <div className="flex flex-col items-center justify-center md:items-start md:justify-start">
            <div className="flex flex-col font-medium -tracking-wider text-4xl">
              <div className="flex">
                <p>ashish</p>
                <p className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500">&nbsp;._</p>
              </div>
              <p className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500 mb-4 text-sm font-medium tracking-tighter">
                Portfolio Website
              </p>
            </div>
            <div className="flex items-center justify-center gap-4 sm:gap-6">
              <a target="_blank" href="https://github.com/Ashish4793">
                <div className="group relative inline-block">
                  <img
                    alt="Github"
                    loading="lazy"
                    width={48}
                    height={48}
                    decoding="async"
                    data-nimg={1}
                    className="w-8"
                    style={{ color: "transparent" }}
                    src="../assets/img/social/github-fill.svg"
                  />
                  <p className="invisible absolute whitespace-nowrap rounded-md bg-black p-2 text-xs text-neutral-950 opacity-0 shadow-sm duration-100 ease-linear group-hover:visible group-hover:opacity-100 left-1/2 top-full mt-2 -translate-x-1/2 false false false">
                    Github
                  </p>
                </div>
              </a>
              <a target="_blank" href="https://www.linkedin.com/in/ashishk4793">
                <div className="group relative inline-block">
                  <img
                    alt="Linkedin"
                    loading="lazy"
                    width={48}
                    height={48}
                    decoding="async"
                    data-nimg={1}
                    className="w-8"
                    style={{ color: "transparent" }}
                    src="../assets/img/social/linkedin.svg"
                  />
                  <p className="invisible absolute whitespace-nowrap rounded-md bg-black p-2 text-xs text-neutral-950 opacity-0 shadow-sm duration-100 ease-linear group-hover:visible group-hover:opacity-100 left-1/2 top-full mt-2 -translate-x-1/2 false false false">
                    Linkedin
                  </p>
                </div>
              </a>
              <a target="_blank" href="https://twitter.com/AshishK4793">
                <div className="group relative inline-block">
                  <img
                    alt="x"
                    loading="lazy"
                    width={42}
                    height={42}
                    decoding="async"
                    data-nimg={1}
                    className="w-7"
                    style={{ color: "transparent" }}
                    src="../assets/img/social/twitter.svg"
                  />
                  <p className="invisible absolute whitespace-nowrap rounded-md bg-black p-2 text-xs text-neutral-950 opacity-0 shadow-sm duration-100 ease-linear group-hover:visible group-hover:opacity-100 left-1/2 top-full mt-2 -translate-x-1/2 false false false">
                    X
                  </p>
                </div>
              </a>
              <a target="_blank" href="https://instagram.com/">
                <div className="group relative inline-block">
                  <img
                    alt="Instagram"
                    loading="lazy"
                    width={48}
                    height={48}
                    decoding="async"
                    data-nimg={1}
                    className="w-8"
                    style={{ color: "transparent" }}
                    src="../assets/img/social/instagram.svg"
                  />
                  <p className="invisible absolute whitespace-nowrap rounded-md bg-black p-2 text-xs text-neutral-950 opacity-0 shadow-sm duration-100 ease-linear group-hover:visible group-hover:opacity-100 left-1/2 top-full mt-2 -translate-x-1/2 false false false">
                    Instagram
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center  font-Edu justify-center gap-1  md:items-end md:justify-start">
            <p className="text-3xl font-bold">Contact Me!</p>
            <p className="text-white">
              Feel free to reach out
            </p>
            <a
              className="px-2 text-white hover:bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500"
              href="mailto:ashishku1063@outlook.com"
            >
              ashishku1063@outlook.com
            </a>
          </div>
        </div>
        <div className="mx-auto text-white flex w-full max-w-[1140px] items-center justify-center px-4 py-8 text-center text-sm ">
          Made with ❤️ from Ashish
        </div>
      </div>
    </footer>
  );
}

export default Footer;
