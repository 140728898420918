import React from "react";

function Card({name,description,img_url,demo_url,github_url,internal_url}){
    return(
        <div class="rounded-lg mx-3 mb-5 md:mx-0 shadow bg-neutral-900">
        <div className="m-3">
          <img
            class="rounded-md"
            src={img_url}
            alt={name}          />
        </div>
        <div class="p-5">
          <h5 class="mb-3 text-3xl font-bold tracking-tight text-white hover:bg-gradient-to-r from-pink-500 to-orange-500 hover:bg-clip-text hover:text-transparent">
            {name}
          </h5>
          <p class="mb-3 font-normal text-white">
            {description}
            <br />
            <a
              href={`/project/${internal_url}`}
              className="bg-clip-text text-xs hover:underline hover:text-gray-400 text-transparent bg-gradient-to-r from-pink-500 to-orange-500"
            >
              Learn more
            </a>
          </p>
          <a
            href={github_url} target="_blank" rel="noreferrer"
            class="inline-flex mt-2 mr-2 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg bg-gradient-to-r from-pink-500 to-orange-500  group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white"
          >
            GitHub
            <svg
              className="ml-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              fill="rgba(255,255,255,1)"
            >
              <path d="M12.001 2C6.47598 2 2.00098 6.475 2.00098 12C2.00098 16.425 4.86348 20.1625 8.83848 21.4875C9.33848 21.575 9.52598 21.275 9.52598 21.0125C9.52598 20.775 9.51348 19.9875 9.51348 19.15C7.00098 19.6125 6.35098 18.5375 6.15098 17.975C6.03848 17.6875 5.55098 16.8 5.12598 16.5625C4.77598 16.375 4.27598 15.9125 5.11348 15.9C5.90098 15.8875 6.46348 16.625 6.65098 16.925C7.55098 18.4375 8.98848 18.0125 9.56348 17.75C9.65098 17.1 9.91348 16.6625 10.201 16.4125C7.97598 16.1625 5.65098 15.3 5.65098 11.475C5.65098 10.3875 6.03848 9.4875 6.67598 8.7875C6.57598 8.5375 6.22598 7.5125 6.77598 6.1375C6.77598 6.1375 7.61348 5.875 9.52598 7.1625C10.326 6.9375 11.176 6.825 12.026 6.825C12.876 6.825 13.726 6.9375 14.526 7.1625C16.4385 5.8625 17.276 6.1375 17.276 6.1375C17.826 7.5125 17.476 8.5375 17.376 8.7875C18.0135 9.4875 18.401 10.375 18.401 11.475C18.401 15.3125 16.0635 16.1625 13.8385 16.4125C14.201 16.725 14.5135 17.325 14.5135 18.2625C14.5135 19.6 14.501 20.675 14.501 21.0125C14.501 21.275 14.6885 21.5875 15.1885 21.4875C19.259 20.1133 21.9999 16.2963 22.001 12C22.001 6.475 17.526 2 12.001 2Z"></path>
            </svg>{" "}
          </a>
          <a
            href={demo_url } target="_blank" rel="noreferrer"
            class="inline-flex mt-2 mr-2 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg bg-gradient-to-r from-pink-500 to-orange-500  group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white"
          >
            Demo
            <svg
              className="ml-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              fill="rgba(255,255,255,1)"
            >
              <path d="M10 6V8H5V19H16V14H18V20C18 20.5523 17.5523 21 17 21H4C3.44772 21 3 20.5523 3 20V7C3 6.44772 3.44772 6 4 6H10ZM21 3V12L17.206 8.207L11.2071 14.2071L9.79289 12.7929L15.792 6.793L12 3H21Z"></path>
            </svg>
          </a>
        </div>
      </div>
    );
}

export default Card;