const listProjects = [
    {
        id: 1,
        name: 'DesiRacingCo',
        shortDesc : 'A motorsport news website',
        description: 'A motorsport news website catering to the passionate community of Indian motorsport enthusiasts. The platform serves as a hub for the latest motorsport news and articles, infused with an Indian touch.',
        imageURL: 'assets/img/project-ss/drc.webp',
        githubURL: 'https://github.com/NielRanawat/DRC',
        demoURL: 'https://dub.sh/project-drc',
        modalID: '#drc-modal',
        tags: ['Bootstrap', 'Node.js', 'AWS'],
        detailedDescription : 'A Motorsport news website, built it for a media company that caters to the passionate community of Indian motorsport enthusiasts. The platform serves as a hub for the latest motorsport news and articles, infused with an Indian touch. Users also can contribute by submitting articles, which are meticulously reviewed by administrators before publication, giving contributors due credit for their contributions.',
        tech_used : [
            {
                name : 'Tailwind',
                iconPath : '../assets/img/skill-icons/tailwindcss-color.svg'
            },
            {
                name : 'Javascript',
                iconPath : '../assets/img/skill-icons/javascript.svg'
            },
            {
                name : 'Node.js',
                iconPath : '../assets/img/skill-icons/nodejs.svg'
            },
            {
                name : 'AWS EC2',
                iconPath : '../assets/img/skill-icons/aws-ec2.svg'
            },
            {
                name : 'MongoDB',
                iconPath : '../assets/img/skill-icons/mongodb-color.svg'
            },
            {
                name : 'Express.js',
                iconPath : '../assets/img/skill-icons/express-color.svg'
            }
        ],
        keyTech : [
            'HTML,CSS,JavaScript - Frontend',
            'Tailwind & Preline - UI',
            'Node.js & Express.js - Backend Server',
            'MongoDB & Mongoose - Database , ODM',
            'Passport.js - Authentication',
            'Nginx - Reverse Proxy',
            'AWS EC2 - Backend Server Hosting',
            'Google Analytics : Website analytics'
        ],
        keyPoints : [
            'Admins have full control over the platform, including post creation, publishing, and review.',
            'Users can contribute their own articles, fostering a community-driven platform that encourages engagement and participation.',
            'Users articles can be reviewed by administrators to ensure the quality and accuracy of submitted articles before publication.',
            'Social Media Integration: Share articles via WhatsApp and X (previously Twitter).',
            'Admin Dashboard: Review submitted posts, approve, reject, or request revisions.',
            'User authentication with username and password and Google OAuth strategies.',
            'Added Google Analytics to track user interactions and website traffic.'
        ],
        deployment : "DesiRacingCo is deployed on AWS infrastructure. We have used EC2 instances with Auto Scaling for hosting, allowing for high flexibility and scalability as per traffic demands. Additionally, we have implemented a Virtual Private Cloud (VPC) to isolate our network, ensuring enhanced security and privacy. Within the VPC, we've set up subnets to manage and segregate our network traffic effectively. Also to fortify our security measures, we have configured security groups, allowing us to control both inbound and outbound traffic effectively. Furthermore, We have integrated an Elastic Load Balancer (ELB). The ELB efficiently distributes incoming traffic across the EC2 instances ensuring high availability and fault tolerance of the application."
    },
    {
        id: 2,
        name: 'EazyBus',
        shortDesc : 'Simple E-commerce web app',
        description: '"EazyBus" is your all-in-one web app for hassle-free bus booking. It lets you reserve seats, process payments using Stripe and Razorpay, ensures secure login via local and Google OAuth.',
        imageURL: 'assets/img/project-ss/eazybus.webp',
        githubURL: 'https://github.com/Ashish4793/eazybus',
        demoURL: 'https://dub.sh/url-redirect-eb',
        modalID: '#eazybus-modal',
        tags: ['AWS', 'Node.js', 'MongoDB'],
        detailedDescription : '"EazyBus" is a full-stack web application designed to simplify the bus booking experience. It offers users the ability to book seats, manage their bookings, and seamlessly process payments through Stripe and Razorpay payment gateways. The app ensures secure user authentication through local sign-up and Google OAuth, provides email notification functionality for booking updates and transactional messages, and dynamically blocks seats to prevent overlapping bookings. Furthermore, it utilizes AWS S3 to store and retrieve invoices and is hosted on AWS EC2 using NGINX for a robust and reliable user experience.',
        tech_used : [
            {
                name : 'Bootstrap',
                iconPath : '../assets/img/skill-icons/bootstrap.svg'
            },
            {
                name : 'Javascript',
                iconPath : '../assets/img/skill-icons/javascript.svg'
            },
            {
                name : 'Node.js',
                iconPath : '../assets/img/skill-icons/nodejs.svg'
            },
            {
                name : 'AWS S3',
                iconPath : '../assets/img/skill-icons/aws-s3.svg'
            },
            {
                name : 'MongoDB',
                iconPath : '../assets/img/skill-icons/mongodb-color.svg'
            },
            {
                name : 'Stripe',
                iconPath : '../assets/img/skill-icons/stripe.svg'
            }
        ],
        keyTech : [
            'HTML,CSS,JS - Frontend',
            'Bootstrap - UI',
            'Node.js & Express.js - Backend Server',
            'MongoDB - Database',
            'Mongoose - Object Document Mapper',
            'Passport.js - Authentication',
            'Nginx - Reverse Proxy',
            'AWS S3 - For storage',
            'AWS EC2 - Backend Server Hosting',
            'Stripe & Razorpay - For payments'
        ],
        keyPoints : [
            'Intuitive seat reservation system for a hassle-free experience',
            'Seamlessly integrated with Stripe and Razorpay for secure transactions.',
            'User authentication with Local (username + password) and Google OAuth strategies.',
            'Email notification service for transactional and booking updates.',
            'Dynamic seat blocking to prevent overlapping bookings.',
            'AWS S3 is used to store and retrieve invoices.',
        ],
        deployment : "null"
    },
    {
        id: 3,
        name: 'SecureVault',
        shortDesc : 'A Cloud storage app',
        description: 'Secure Vault is a web application designed to offer a secure and reliable storage solution for users to store and manage their important documents and files in the cloud.',
        imageURL: 'assets/img/project-ss/secure-vault.webp',
        githubURL: 'https://github.com/Ashish4793/secure-vault',
        demoURL: 'https://dub.sh/url-redirect-secure-vault',
        modalID: '#secureVault-modal',
        tags: ['AWS S3' ,'Node.js', 'daisyUI',],
        detailedDescription : 'Secure Vault is a web application designed to offer a secure and reliable storage solution for users to store and manage their important documents and files in the cloud. Leveraging the power of AWS S3, Secure Vault ensures top-notch security through server-side encryption in the AWS S3 bucket. This ensures that all stored files are encrypted, providing an additional layer of protection for sensitive data. With a user-friendly interface and robust security measures.',
        tech_used : [
            {
                name : 'Tailwind',
                iconPath : '../assets/img/skill-icons/tailwindcss-color.svg'
            },
            {
                name : 'Javascript',
                iconPath : '../assets/img/skill-icons/javascript.svg'
            },
            {
                name : 'Node.js',
                iconPath : '../assets/img/skill-icons/nodejs.svg'
            },
            {
                name : 'AWS S3',
                iconPath : '../assets/img/skill-icons/aws-s3.svg'
            },
            {
                name : 'MongoDB',
                iconPath : '../assets/img/skill-icons/mongodb-color.svg'
            },
            {
                name : 'Express.js',
                iconPath : '../assets/img/skill-icons/express-color.svg'
            }
        ],
        keyTech : [
            'HTML,CSS,JavaScript - Frontend',
            'Tailwind & daisyUI - UI',
            'Node.js & Express.js - Backend Server',
            'MongoDB & Mongoose - Database , ODM',
            'Passport.js - Authentication',
            'AWS S3 - For storage',
            'Render - For hosting',
        ],
        keyPoints : [
            'Users can easily upload their important documents and files directly to the Secure Vault platform, ensuring seamless and secure transfer of data to the AWS S3 cloud storage.',
            'Users can download their stored files at any time, providing easy access to their documents while maintaining the highest standards of security.',
            'Users have the capability to delete files from the Secure Vault, ensuring complete control over their stored data.',
            'All files stored within the AWS S3 bucket are automatically encrypted using AWS Server-side Encryption(SSE), guaranteeing that sensitive data remains protected from unauthorized access or data breaches.',
            'Secure Vault implements robust user authentication mechanisms to ensure that only authorized users can access, upload, download, or delete files from their personal storage space.',
            'User authentication with username and password strategy.',
            'Built on the scalable and reliable infrastructure of AWS, Secure Vault guarantees high availability and durability for stored data, offering a dependable solution for long-term file storage needs.'
        ],
        deployment : "null"  
    },

    {
        id: 4,
        name: 'EazyBus-Admin',
        shortDesc : 'Admin management app',
        description: 'EazyBus-Admin serves as a comprehensive management platform for EazyBus. It facilitates efficient handling of bookings, buses, services, and enables administrators to monitor user booking and payment statuses with ease.',
        imageURL: 'assets/img/project-ss/eazybus-admin.webp',
        githubURL: '#private-repo',
        demoURL: 'https://dub.sh/url-redirect-aeb',
        modalID: '#eazybusAdmin-modal',
        tags: ['Bootstrap' ,'MongoDB', 'Node.js',],
        detailedDescription : "EazyBus-Admin serves as a comprehensive management platform for EazyBus. It empowers administrators to efficiently manage and modify bookings, buses, and services. Additionally, it provides convenient monitoring of user bookings , payment status and process refunds. It also enables administrators to gain valuable revenue insights, including real-time data on today's revenue and the current month's revenue, ensuring effective financial tracking and analysis.",
        tech_used : [
            {
                name : 'Bootstrap',
                iconPath : '../assets/img/skill-icons/bootstrap.svg'
            },
            {
                name : 'Javascript',
                iconPath : '../assets/img/skill-icons/javascript.svg'
            },
            {
                name : 'Node.js',
                iconPath : '../assets/img/skill-icons/nodejs.svg'
            },
            {
                name : 'Stripe',
                iconPath : '../assets/img/skill-icons/stripe.svg'
            },
            {
                name : 'MongoDB',
                iconPath : '../assets/img/skill-icons/mongodb-color.svg'
            },
            {
                name : 'Express.js',
                iconPath : '../assets/img/skill-icons/express-color.svg'
            }
        ],
        keyTech : [
            'HTML,CSS,JavaScript - Frontend',
            'Bootstrap - UI',
            'Node.js & Express.js - Backend Server',
            'MongoDB & Mongoose - Database , ODM',
            'Passport.js - Authentication',
            'Stripe & Razorpay - Payment APIs',
            'Render - For hosting',
        ],
        keyPoints : [
            'Booking Management: Administrators can manage and modify user bookings.',
            'Bus Management: Efficient handling of buses, including scheduling and availability.',
            'Service Management: Streamlined management of bus services and routes.',
            'Payment Tracking: Easy monitoring of user payment statuses.',
            'Refund Processing: Administrators have the capability to process refunds for user bookings.',
            'User Permissions: Role-based access control for multiple administrators.',
            "Revenue Insights: Real-time data on today's revenue and the current month's revenue for financial tracking and analysis"
        ],
        deployment : "null"
    },
    {
        id: 5,
        name: 'Portfolio Website',
        shortDesc : 'My portfolio website',
        description: "Built my portfolio website using Tailwind, React, and DaisyUI. The website is designed to showcase my work and skills in an easy-to-navigate and attractive way. It's responsive and user-friendly, highlighting my projects effectively.",
        imageURL: 'assets/img/project-ss/portfolio.png',
        githubURL: '#private-repo',
        demoURL: 'https://ashish-kumar.vercel.app',
        modalID: '#portfolio-modal',
        tags: ['React.js', 'Tailwind', 'daisyUI'],
        detailedDescription : "Built my portfolio website using Tailwind, React, and DaisyUI. The website is designed to showcase my work and skills in an easy-to-navigate and attractive way. It's responsive and user-friendly, highlighting my projects effectively.",
        tech_used : [
            {
                name : 'React',
                iconPath : '../assets/img/skill-icons/react.svg'
            },
            {
                name : 'Tailwind',
                iconPath : '../assets/img/skill-icons/tailwindcss-color.svg'
            },
            {
                name : 'daisyUI',
                iconPath : '../assets/img/skill-icons/daisyui-color.svg'
            },
            {
                name : 'Javascript',
                iconPath : '../assets/img/skill-icons/javascript.svg'
            },
        ],
        keyTech : [
            'React - Frontend',
            'Tailwind - UI',
            'Vercel - For hosting',
            'Google Analytics : Website analytics'
        ],
        keyPoints : [
            'Portfolio website'
        ],
        deployment : "null"
    },
    {
        id: 6,
        name: 'StreamScan',
        shortDesc : 'Streaming availability app',
        description: 'StreamScan is your go-to solution for discovering the streaming availability of movies and TV shows.It not only helps you find where to watch your favorite content but also provides additional information such as release date, IMDb ratings, and cast details.',
        imageURL: 'assets/img/project-ss/streamscan.webp',
        githubURL: 'https://github.com/Ashish4793/streamscan',
        demoURL: 'https://streamscan.netlify.com/',
        modalID: '#streamscan-modal',
        tags: ['React.js', 'APIs', 'Bootstrap'],
        detailedDescription : 'StreamScan is your go-to solution for discovering the streaming availability of movies and TV shows. Powered by OMDb and the Streaming Availability API, it not only helps you find where to watch your favorite content but also provides additional information such as release date, IMDb ratings, and cast details.',
        tech_used : [
            {
                name : 'Tailwind',
                iconPath : '../assets/img/skill-icons/tailwindcss-color.svg'
            },
            {
                name : 'Javascript',
                iconPath : '../assets/img/skill-icons/javascript.svg'
            },
            {
                name : 'Node.js',
                iconPath : '../assets/img/skill-icons/nodejs.svg'
            },
            {
                name : 'AWS EC2',
                iconPath : '../assets/img/skill-icons/aws-ec2.svg'
            },
            {
                name : 'MongoDB',
                iconPath : '../assets/img/skill-icons/mongodb-color.svg'
            },
            {
                name : 'Express.js',
                iconPath : '../assets/img/skill-icons/express-color.svg'
            }
        ],
        keyTech : [
            'React - Frontend',
            'Bootstrap - UI',
            'OMDB API & Streaming availabilty API - APIs',
            'Netlify - For Hosting'
        ],
        keyPoints : [
            'Users can search for movies and tv shows.',
            'Users can easily discover the streaming availability of movies and TV shows across various platforms.',
            'Users can also access detailed information including release dates, IMDb ratings, cast details , etc.'
        ],
        deployment : "null"
    },
    {
        id: 7,
        name: 'NewsG',
        shortDesc : 'News App',
        description: 'Discover the latest news effortlessly with NewsG, a basic news app powered by the GNews API. Users can easily search for news using keywords or categories, staying informed on their terms.',
        imageURL: 'assets/img/project-ss/newsg.webp',
        githubURL: 'https://github.com/Ashish4793/news-app',
        demoURL: 'https://dub.sh/project-newsg',
        modalID: '#newsg-modal',
        tags: ['Bootstrap', 'Node.js', 'NewsG API'],
        detailedDescription : 'Discover the latest news effortlessly with NewsG, a basic news app powered by the GNews API. Users can easily search for news using keywords or categories, staying informed on their terms.',
        tech_used : [
            {
                name : 'Bootstrap',
                iconPath : '../assets/img/skill-icons/bootstrap.svg'
            },
            {
                name : 'Javascript',
                iconPath : '../assets/img/skill-icons/javascript.svg'
            },
            {
                name : 'Node.js',
                iconPath : '../assets/img/skill-icons/nodejs.svg'
            },
            {
                name : 'MongoDB',
                iconPath : '../assets/img/skill-icons/mongodb-color.svg'
            },
            {
                name : 'Express.js',
                iconPath : '../assets/img/skill-icons/express-color.svg'
            }
        ],
        keyTech : [
            'HTML,CSS,JavaScript - Frontend',
            'Tailwind & Preline - UI',
            'Node.js & Express.js - Backend Server',
            'MongoDB & Mongoose - Database , ODM',
            'Passport.js - Authentication',
            'GNews API - APIs',
            'Cyclic - For Hosting'
        ],
        keyPoints : [
            'Users can access the latest news from various sources in one platform.',
            'Users can find specific news articles using keywords for quick and precise results.',
            'Browse news articles conveniently categorized for easy exploration and navigation.',
            'Save articles for later reading with a simple bookmarking option.',
            'User authentication through Local (username + password), Google OAuth, and Facebook OAuth strategies.',
            'Multilingual Support: Provide news content in multiple languages for a diverse user base.',
            'Global Coverage: Cater to a worldwide audience by offering news from different countries.'
        ],
        deployment : "null"
    },
    
]
export default listProjects;