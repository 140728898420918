const skills = [
    {
        name : "HTML",
        iconURL : "../assets/img/skill-icons/html-5.svg"
    },
    {
        name : "CSS",
        iconURL : "../assets/img/skill-icons/css-3.svg"
    },
    {
        name : "JavaScript",
        iconURL : "../assets/img/skill-icons/javascript.svg"
    },
    {
        name : "AWS",
        iconURL : "../assets/img/skill-icons/aws.svg"
    },
    {
        name : "Bootstrap",
        iconURL : "../assets/img/skill-icons/bootstrap.svg"
    },
    {
        name : "React",
        iconURL : "../assets/img/skill-icons/react.svg"
    },
    {
        name : "Tailwind",
        iconURL : "../assets/img/skill-icons/tailwindcss-color.svg"
    },
    {
        name : "daisyUI",
        iconURL : "../assets/img/skill-icons/daisyui-color.svg"
    },
    {
        name : "Node.js",
        iconURL : "../assets/img/skill-icons/nodejs.svg"
    },
    {
        name : "Express.js",
        iconURL : "../assets/img/skill-icons/express-color.svg"
    },
    {
        name : "Mongoose",
        iconURL : "../assets/img/skill-icons/mongoose-color.svg"
    },
    {
        name : "MongoDB",
        iconURL : "../assets/img/skill-icons/mongodb-color.svg"
    },
    {
        name : "AWS EC2",
        iconURL : "../assets/img/skill-icons/aws-ec2.svg"
    },
    {
        name : "AWS S3",
        iconURL : "../assets/img/skill-icons/aws-s3.svg"
    },
    {
        name : "AWS DynamoDB",
        iconURL : "../assets/img/skill-icons/aws-dynamodb.svg"
    },
    {
        name : "AWS CloudFront",
        iconURL : "../assets/img/skill-icons/aws-cloudfront.svg"
    },
    {
        name : "AWS Lambda",
        iconURL : "../assets/img/skill-icons/aws-lambda.svg"
    },
    {
        name : "AWS Aurora",
        iconURL : "../assets/img/skill-icons/aws-aurora.svg"
    },
    {
        name : "Docker",
        iconURL : "../assets/img/skill-icons/docker-icon.svg"
    },
    {
        name : "Kubernetes",
        iconURL : "../assets/img/skill-icons/kubernetes.svg"
    },
    {
        name : "JWT",
        iconURL : "../assets/img/skill-icons/jwt-icon.svg"
    },
    {
        name : "Git",
        iconURL : "../assets/img/skill-icons/git-color.svg"
    },
    {
        name : "GitHub",
        iconURL : "../assets/img/skill-icons/github-color.svg"
    },
    {
        name : "MySQL",
        iconURL : "../assets/img/skill-icons/mysql.svg"
    },
    {
        name : "jQuery",
        iconURL : "../assets/img/skill-icons/jquery-color.svg"
    },
    {
        name : "NGINX",
        iconURL : "../assets/img/skill-icons/nginx-color.svg"
    },
    {
        name : "EJS",
        iconURL : "../assets/img/skill-icons/ejs.svg"
    },
    {
        name : "Passport.js",
        iconURL : "../assets/img/skill-icons/passport-color.svg"
    },
    {
        name : "Stripe",
        iconURL : "../assets/img/skill-icons/stripe.svg"
    },
]


export default skills;