import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import listProjects from "./listProjects";

function Project() {
  let { id } = useParams();
  id = parseInt(id);
  const foundProject = listProjects.find((project) => project.id === id);
  if (foundProject) {
    return (
      <div className="bg-black text-white font-Kanit">
        <Header />
        {/* Blog Article */}
        <div className="max-w-6xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
          <div className="max-w-6xl mx-3 md:mx-0">
            {/* Content */}
            <div className="space-y-5 md:space-y-8">
              <div className="space-y-3">
                <h2 className="text-4xl mt-5 text-white font-bold md:text-5xl">
                  {foundProject.name}
                </h2>
                <p className="text-2xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500">
                  {foundProject.shortDesc}
                </p>
              </div>
              <figure>
                <img
                  className="w-full object-cover shadow-2xl rounded-xl"
                  src={`../${foundProject.imageURL}`} alt=""
                />
              </figure>

              <div class="grid grid-rows-1 mb-5 md:grid-flow-col">
                <div class="row-span-1 md:col-span-4">
                  <h3 className="text-2xl md:text-3xl my-2">Description</h3>
                  <p className="text-base md:text-lg text-white null:text-gray-200 text-justify">
                    {foundProject.detailedDescription}
                  </p>
                </div>
                <div class="col-span-3 md:col-span-1">
                  <div className="md:mr-36 md:ml-5">
                    <h3 className="text-2xl md:text-3xl my-2">Links</h3>
                    <a
                      href={foundProject.githubURL}
                      target="_blank"
                      rel="noreferrer"
                      class="inline-flex mt-2 mr-2 items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-gradient-to-r from-pink-500 to-orange-500 hover:shadow-2xl"
                    >
                      GitHub
                      <svg
                        className="ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        fill="rgba(255,255,255,1)"
                      >
                        <path d="M12.001 2C6.47598 2 2.00098 6.475 2.00098 12C2.00098 16.425 4.86348 20.1625 8.83848 21.4875C9.33848 21.575 9.52598 21.275 9.52598 21.0125C9.52598 20.775 9.51348 19.9875 9.51348 19.15C7.00098 19.6125 6.35098 18.5375 6.15098 17.975C6.03848 17.6875 5.55098 16.8 5.12598 16.5625C4.77598 16.375 4.27598 15.9125 5.11348 15.9C5.90098 15.8875 6.46348 16.625 6.65098 16.925C7.55098 18.4375 8.98848 18.0125 9.56348 17.75C9.65098 17.1 9.91348 16.6625 10.201 16.4125C7.97598 16.1625 5.65098 15.3 5.65098 11.475C5.65098 10.3875 6.03848 9.4875 6.67598 8.7875C6.57598 8.5375 6.22598 7.5125 6.77598 6.1375C6.77598 6.1375 7.61348 5.875 9.52598 7.1625C10.326 6.9375 11.176 6.825 12.026 6.825C12.876 6.825 13.726 6.9375 14.526 7.1625C16.4385 5.8625 17.276 6.1375 17.276 6.1375C17.826 7.5125 17.476 8.5375 17.376 8.7875C18.0135 9.4875 18.401 10.375 18.401 11.475C18.401 15.3125 16.0635 16.1625 13.8385 16.4125C14.201 16.725 14.5135 17.325 14.5135 18.2625C14.5135 19.6 14.501 20.675 14.501 21.0125C14.501 21.275 14.6885 21.5875 15.1885 21.4875C19.259 20.1133 21.9999 16.2963 22.001 12C22.001 6.475 17.526 2 12.001 2Z"></path>
                      </svg>{" "}
                    </a>
                    <a
                      href={foundProject.demoURL}
                      target="_blank"
                      rel="noreferrer"
                      class="inline-flex mt-2 mr-2 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg bg-gradient-to-r from-pink-500 to-orange-500  group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white"
                    >
                      Demo
                      <svg
                        className="ml-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        fill="rgba(255,255,255,1)"
                      >
                        <path d="M10 6V8H5V19H16V14H18V20C18 20.5523 17.5523 21 17 21H4C3.44772 21 3 20.5523 3 20V7C3 6.44772 3.44772 6 4 6H10ZM21 3V12L17.206 8.207L11.2071 14.2071L9.79289 12.7929L15.792 6.793L12 3H21Z"></path>
                      </svg>
                    </a>

                    <h3 className="text-2xl md:text-3xl mt-8">
                      Technologies Used
                    </h3>
                    <div className="flex my-5 flex-wrap">
                      {foundProject.tech_used.map((skill, index) => (
                        <button
                          key={index}
                          type="button"
                          className="text-white bg-neutral-900 m-1 border border-neutral-900 hover:border-orange-700	 font-medium rounded-lg text-xs md:text-sm px-3 md:px-4 py-2 me-2 mb-2 flex items-center"
                        >
                          <img
                            className="w-3.5 md:w-4 mr-2"
                            src={skill.iconPath}
                            alt=""
                          />
                          {skill.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid grid-rows-1 md:grid-flow-col gap-4">
                <div class="row-span-3 md:col-span-2">
                  <h3 className="text-2xl md:text-3xl my-2">🚀 Key Features</h3>
                  <ul class="list-disc ml-5 my-4 grow">
                    {foundProject.keyPoints.map((point, index) => (
                      <li className="hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r from-pink-500 to-orange-500 ">
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {foundProject.deployment === "null" ? <p></p> : <div class="grid grid-rows-1 md:grid-flow-col gap-4">
                <div class="row-span-3 md:col-span-2">
                  <h3 className="text-2xl md:text-3xl my-2">🧵 Deployment</h3>
                  <p className='max-w-3xl mt-3 text-justify'>{foundProject.deployment}   </p>             
                  </div>
              </div>}


              <div class="grid grid-rows-1 md:grid-flow-col gap-4">
                <div class="row-span-3 md:col-span-2">
                  <h3 className="text-2xl md:text-3xl my-2">⚙️ Tech Stack</h3>
                  <ul class="list-disc ml-5 my-4 grow">
                    {foundProject.keyTech.map((tech, index) => (
                      <li className="hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r from-pink-500 to-orange-500 ">
                        {tech}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Sticky Share Group */}
        <Footer />
      </div>
    );
  } else {
    return (
      <div class="h-screen text-white font-Kanit bg-black flex flex-col mx-auto">
        <header class="mb-auto flex justify-center z-50 w-full py-4">
          <nav class="px-4 sm:px-6 lg:px-8" aria-label="Global">
          <div className="flex flex-col font-medium -tracking-wider text-4xl">
              <div className="flex">
                <p>ashish</p>
                <p className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500">&nbsp;._</p>
              </div>
              <p className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500 mb-4 text-sm font-medium tracking-tighter">
                Portfolio Website
              </p>
            </div>
          </nav>
        </header>

        <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
          <h1 class="block text-7xl font-bold text-white sm:text-9xl null:text-white">
            404
          </h1>
          <h1 class="block text-2xl font-bold text-white"></h1>
          <p class="mt-3 text-gray-200 null:text-gray-400">
            Oops, something went wrong.
          </p>
          <p class="text-gray-200 null:text-gray-400">
            Sorry, we couldn't find your page.
          </p>
          <div class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
            <a
              class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none null:text-blue-500 null:hover:text-blue-400 null:focus:outline-none null:focus:ring-1 null:focus:ring-gray-600"
              href="/"
            >
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
              Back to home
            </a>
          </div>
        </div>

        <footer class="mt-auto text-center py-5">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"></div>
        </footer>
      </div>
    );
  }
}

export default Project;
