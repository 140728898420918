import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import ScrollToTop from "./ScrolltoTop";
import Header from "./Header";
import Footer from "./Footer";
import listProjects from "./listProjects";
import Card from "./components/Card";

function Projects() {
  return (
    <div className="bg-black font-Kanit">

    <Header />

    <section>
        <div className="md:max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <h2 className="text-5xl mt-8 ml-4 mb-2 text-bold text-white flex container">
            Projects
          </h2>
          <p className="ml-4 mb-10 text-xl bg-gradient-to-r from-pink-500 to-orange-500 text-transparent bg-clip-text">My Best Work</p>


          <div class="grid md:grid-cols-3 gap-6">
            {listProjects.map((project,index) => (
              
                          <Card
                           name= {project.name}
                           img_url = {project.imageURL}
                           description = {project.description}
                           demo_url = {project.demoURL}
                           github_url = {project.githubURL}
                           internal_url = {project.id}
                           />
            ))}          
        </div>
        <div class="mt-12 text-center">
            <a href="/">
            <button class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white focus:ring-4 focus:outline-none focus:ring-pink-200">
                <span class="relative px-5 py-2.5 transition-all ease-in duration-75 text-white rounded-md group-hover:bg-opacity-0">
                  Back to home <i class="ri-arrow-right-line"></i>
                </span>
              </button>
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Projects;
