import React from "react";

function Header() {
  return (
    <header class="sticky  top-4 z-50 flex items-center justify-center md:top-6">
      <nav class="bg-purple-200 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 flex w-fit text-white items-center justify-between gap-6 rounded-full px-4 py-2 transition-all duration-300 ease-linear sm:px-6 sm:py-3 md:gap-12">
        <a href="/">
          <div class="flex flex-col  font-medium -tracking-wider text-xl">
            <div class="flex">
              <a
                class="flex-none text-xl text-white"
                href="/"
                aria-label="name"
              >
                ashish{" "}
                <span className="bg-gradient-to-r from-pink-500 to-orange-500 text-transparent bg-clip-text">
                  ._
                </span>
              </a>
            </div>
          </div>
        </a>
        <a
          aria-label="About"
          class="flex flex-col hover:cursor-pointer items-center justify-center gap-4"
          href="/#about"
        >
          <p class="text-xs hover:cursor-pointer sm:text-lg md:flex">about</p>
        </a>

        <a
          aria-label="Tech"
          class="flex flex-col hover:cursor-pointer items-center justify-center gap-4"
          href="/#techstack"
        >
          <p class="text-xs hover:cursor-pointer sm:text-lg md:flex">tech</p>
        </a>
        <a
          aria-label="Projects"
          class="flex flex-col hover:cursor-pointer items-center justify-center gap-4"
          href="/projects"
        >
          <p class="text-xs hover:cursor-pointer sm:text-lg md:flex">
            projects
          </p>
        </a>
        <a
          aria-label="contact"
          class="flex flex-col hover:cursor-pointer items-center justify-center gap-4"
          href="#contact"
        >
          <p class="text-xs hover:cursor-pointer sm:text-lg md:flex">contact</p>
        </a>
      </nav>
    </header>
  );
}

export default Header;
