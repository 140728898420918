import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {  createBrowserRouter,  RouterProvider} from "react-router-dom";
import Projects from './Projects'
import Project from './Project'
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path : '/projects',
    element : <Projects />
  },
  {
    path : '/project/:id',
    element : <Project />
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

